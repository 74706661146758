
<template>
    <b-container fluid>
      <iq-card>
        <template v-slot:body>
          <b-row>
            <b-col xs="12" sm="12" md="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('bsri_demandDIS.fiscal_year')"
              label-for="fiscal_year_id"
              >
              <b-form-select
              plain
              v-model="search.fiscal_year_id"
              :options="fiscalYearList"
              id="fiscal_year_id"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('bsri_demandDIS.mill_name')"
              label-for="mill_name"
              >
              <b-form-select
              plain
              v-model="search.mill_info_id"
              :options="millInfoList"
              id="mill_name"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('bsri_demandDIS.subzone_office_name')"
              label-for="subzone_office_id"
              >
              <b-form-select
              plain
              v-model="search.subzone_office_id"
              :options="subZoneList"
              id="subzone_office_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('bsri_demandDIS.center_name')"
              label-for="center_id"
              >
              <b-form-select
              plain
              v-model="search.center_id"
              :options="centerList"
              id="center_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('bsri_demandDIS.unit_no')"
              label-for="unit_id"
              >
              <b-form-select
              plain
              v-model="search.unit_id"
              :options="unitList"
              id="unit_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
          </b-row>
        </template>
      </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_demandDIS.subsidy_requisite_list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(unit_id)="data">
                                            {{ $n(data.item.unit_id, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(total_farmers)="data">
                                            {{ $n(data.item.total_farmers, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(total_subsidized_land)="data">
                                            {{ $n(data.item.total_subsidized_land, { useGrouping: false }) }}
                                        </template>
                                         <template v-slot:cell(verfied)="data">
                                            <span class="badge badge-success" v-if="data.item.checklist_veri_id">{{$t('bsri_demandDIS.done')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('bsri_demandDIS.not_done')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button v-b-modal.modal-show variant=" iq-bg-success mr-1 mb-1" size="sm" @click="show(data.item)"><i class="ri-eye-line m-0"></i></b-button>
                                            <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" v-if="!data.item.checklist_veri_id" @click="detail(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-show" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Show :item="item" :key="item.id"/>
        </b-modal>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Detail :item="item" :key="item.id"/>
        </b-modal>
    </b-container>
</template>
<script>
import Show from './Show'
import Detail from './Detail'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { centerWiseFarmerList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Show, Detail
  },
  data () {
    return {
      search: {
        fiscal_year_id: '0',
        mill_info_id: '0',
        subzone_office_id: '0',
        center_id: '0',
        unit_id: '0'
      },
      fiscalYearId: '',
      subZoneList: [],
      centerList: [],
      unitList: [],
      item: ''
    }
  },
  mounted () {
  },
  computed: {
    fiscalYearList: function () {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        return fiscalYearList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    millInfoList: function () {
      const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
      return millInfoList.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('bsri_demandDIS.farmer_info') : this.$t('bsri_demandDIS.farmer_info') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.fiscal_year'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.mill_name'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.subzone_office_name'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.center_name'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.unit_no'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.total_farmers'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.total_subsidized_land'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'mill_name_bn' },
          { key: 'sub_zone_name_bn' },
          { key: 'center_name_bn' },
          { key: 'unit_id' },
          { key: 'total_farmers' },
          { key: 'total_subsidized_land' },
          { key: 'verfied' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_en' },
          { key: 'mill_name_en' },
          { key: 'sub_zone_name_en' },
          { key: 'center_name_en' },
          { key: 'unit_id' },
          { key: 'total_farmers' },
          { key: 'total_subsidized_land' },
          { key: 'verfied' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.mill_info_id': function (newVal) {
      this.subZoneList = this.getSubZoneList(newVal)
    },
    'search.subzone_office_id': function (newVal) {
      this.centerList = this.getCenterList(newVal)
    },
    'search.center_id': function (newVal) {
      this.unitList = this.getUnitList(newVal)
    }
  },
  created () {
   this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    show (item) {
      this.item = item
    },
    detail (item) {
      this.item = item
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, centerWiseFarmerList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
      const subZoneList = this.$store.state.incentiveGrant.commonObj.subZoneList
      const centerList = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item.status === 1)
      const listData = data.map(item => {
        const fiscalYearObj = fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)
        const fiscalYearData = { fiscal_year_en: fiscalYearObj.text_en, fiscal_year_bn: fiscalYearObj.text_bn }
        const millInfoListObj = millInfoList.find(millInfo => millInfo.value === item.mill_info_id)
        const millInfoData = { mill_name_en: millInfoListObj.text_en, mill_name_bn: millInfoListObj.text_bn }
        const subZoneListObj = subZoneList.find(subZoneInfo => subZoneInfo.value === item.subzone_office_id)
        const subZoneData = { sub_zone_name_en: subZoneListObj.text_en, sub_zone_name_bn: subZoneListObj.text_bn }
        const centerListObj = centerList.find(centerInfo => centerInfo.value === item.center_id)
        const centerData = { center_name_en: centerListObj.text_en, center_name_bn: centerListObj.text_bn }
        return Object.assign({}, item, fiscalYearData, millInfoData, subZoneData, centerData)
      })
      return listData
    },
    getSubZoneList: function (millInfoId) {
        const subZoneList = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item && item.mill_info_id === millInfoId)
        return subZoneList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getCenterList: function (subZoneId) {
        const centerList = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item && item.subzone_office_id === subZoneId)
        return centerList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getUnitList: function (centerId) {
      const unitList = this.$store.state.incentiveGrant.commonObj.unitList.filter(item => item.status === 1 && item.center_id === centerId)
      return unitList.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.unit_no, text: this.$n(item.text_bn) }
          } else {
              return { value: item.unit_no, text: item.text_en }
          }
      })
    }
  }
}
</script>
