<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <b-row>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <table class="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.fiscal_year')}}</th>
                                                                <td>
                                                                    {{ verifyDemandCollection.fiscal_year }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.mill_name')}}</th>
                                                                <td>
                                                                    {{ verifyDemandCollection.mill_name }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.subzone_office_name')}}</th>
                                                                <td>
                                                                    {{ verifyDemandCollection.sub_zone_name }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.center_name')}}</th>
                                                                <td>
                                                                    {{ verifyDemandCollection.center_name }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.unit_no')}}</th>
                                                                <td>
                                                                    {{ $n(verifyDemandCollection.unit_id, { useGrouping: false }) }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.total_farmers')}}</th>
                                                                <td>
                                                                    {{ $n(verifyDemandCollection.total_farmers, { useGrouping: false }) }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.total_subsidized_land')}}</th>
                                                                <td>
                                                                    {{ $n(verifyDemandCollection.total_subsidized_land, { useGrouping: false }) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3 p-1">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.farmers_list') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.sl')}}</th>
                                                                <th>{{$t('bsri_demandDIS.farmer_name')}}</th>
                                                                <th>{{$t('bsri_demandDIS.farmer_nid_number')}}</th>
                                                                <th>{{$t('bsri_demandDIS.crop_name')}}</th>
                                                                <th>{{$t('bsri_demandDIS.method_of_cultivation')}}</th>
                                                                <th>{{$t('bsri_demandDIS.total_land_quantity')}}</th>
                                                                <th>{{$t('bsri_demandDIS.verified')}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(farmerInfo, index) in verifyDemandCollection.farmerList" :key="index">
                                                                <td>
                                                                    {{ $n(index + 1) }}
                                                                </td>
                                                                <td>
                                                                    {{ farmerInfo.farmer_name }}
                                                                </td>
                                                                <td>
                                                                    {{ farmerInfo.nid_no }}
                                                                </td>
                                                                <td>
                                                                    {{ farmerInfo.crop_name }}
                                                                </td>
                                                                <td>
                                                                    {{ farmerInfo.cultivation_medthod_name }}
                                                                </td>
                                                                <td>
                                                                    {{ farmerInfo.total_land }}
                                                                </td>
                                                                <td>
                                                                    <span class="badge badge-success" v-if="farmerInfo.check_veri_id">{{$t('bsri_demandDIS.verified')}}</span>
                                                                    <span class="badge badge-danger" v-else>{{$t('bsri_demandDIS.not_verified')}}</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Comments (En)" vid="comments" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="comments"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comments_en')}}
                                                        </template>
                                                            <b-form-textarea
                                                            disabled
                                                            id="description"
                                                            v-model="verifyDemandCollection.farmerList[0].comment_en"
                                                            rows="1"
                                                            max-rows="2"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Comments (Bn)" vid="comments_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="comments_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comments_bn')}}
                                                        </template>
                                                            <b-form-textarea
                                                            disabled
                                                            id="description"
                                                            v-model="verifyDemandCollection.farmerList[0].comment_bn"
                                                            rows="1"
                                                            max-rows="2"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <div class="col text-right">
                                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver } from 'vee-validate'
import { farmerDataShowList } from '../../api/routes'

export default {
    props: ['item'],
    components: {
        ValidationObserver
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            onlyCreate: true,
            verifyDemandCollection: {
                farmerList: [],
                comments_en: '',
                comments_bn: ''
            },
            terms: false
        }
    },
    created () {
        if (this.item) {
            this.getFarmerList(this.item)
            this.getEditingData(this.item)
        }
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        },
        isDisabled: function () {
            return !this.terms
        }
    },
    methods: {
        getEditingData (item) {
            this.verifyDemandCollection.fiscal_year = this.locale === 'bn' ? item.fiscal_year_bn : item.fiscal_year_en
            this.verifyDemandCollection.mill_name = this.locale === 'bn' ? item.mill_name_bn : item.mill_name_en
            this.verifyDemandCollection.sub_zone_name = this.locale === 'bn' ? item.sub_zone_name_bn : item.sub_zone_name_en
            this.verifyDemandCollection.center_name = this.locale === 'bn' ? item.center_name_bn : item.center_name_en
            this.verifyDemandCollection.fiscal_year_id = item.fiscal_year_id
            this.verifyDemandCollection.mill_info_id = item.mill_info_id
            this.verifyDemandCollection.subzone_office_id = item.subzone_office_id
            this.verifyDemandCollection.center_id = item.center_id
            this.verifyDemandCollection.unit_id = item.unit_id
            this.verifyDemandCollection.total_farmers = item.total_farmers
            this.verifyDemandCollection.total_subsidized_land = item.total_subsidized_land
        },
        async getFarmerList (item) {
            this.loading = true
            RestApi.postData(incentiveGrantServiceBaseUrl, farmerDataShowList, item).then(response => {
                if (response.success) {
                    this.verifyDemandCollection.farmerList = this.getRelatinalData(response.data)
                }
                this.loading = false
            })
        },
        getRelatinalData (data) {
            return data.map(item => {
                const cropList = this.$store.state.incentiveGrant.commonObj.cropList.find(cropInfo => cropInfo.status === 1 && cropInfo.value === item.crop_name_id)
                const cultivationMethodList = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.find(cultivationMethodInfo => cultivationMethodInfo.status === 1 && cultivationMethodInfo.value === item.cultivation_id)
                const cropNameData = { crop_name: this.locale === 'bn' ? cropList.text_bn : cropList.text_en }
                const cultivationMethodData = { cultivation_medthod_name: this.locale === 'bn' ? cultivationMethodList.text_bn : cultivationMethodList.text_en }
                const farmerNameModify = { farmer_name: this.locale === 'bn' ? item.name_bn : item.name }
                return Object.assign({}, item, cropNameData, cultivationMethodData, farmerNameModify)
            })
        }
    }
}
</script>
